// docs https://github.com/michalsnik/aos
import AOS from "aos";

import Dropdown from "flowbite/lib/esm/components/dropdown";
import { initDrawers } from "flowbite/lib/esm/components/drawer";

AOS.init({
  once: true,
  duration: 500,
});

const navbar = document.querySelector("nav.navbar");

function toggleNavbar() {
  const bodyScrollTop = Math.max(
    document.scrollingElement.scrollTop,
    document.documentElement.scrollTop
  );

  if (bodyScrollTop >= 78) {
    navbar.classList.add("is-scrolled");
  } else {
    navbar.classList.remove("is-scrolled");
  }
}

document.addEventListener("scroll", toggleNavbar, { passive: true });

window.addEventListener("load", toggleNavbar);

initDrawers();

function setCookie(name, value, attributes = {}) {
  const hostname = window.location.hostname;
  // Get root domain for production (without leading dot)
  const domain =
    hostname === "localhost"
      ? hostname
      : hostname.split(".").slice(-2).join(".");

  attributes = {
    domain,
    path: "/",
    "max-age": 86400000,
    SameSite: "Lax",
    ...attributes,
  };

  if (attributes.expires instanceof Date) {
    attributes.expires = attributes.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let attributeKey in attributes) {
    updatedCookie += "; " + attributeKey;
    let attributeValue = attributes[attributeKey];
    if (attributeValue !== true) {
      updatedCookie += "=" + attributeValue;
    }
  }

  document.cookie = updatedCookie;
}

// same as flowbites, but with options and return the dropdowns
function initDropdowns() {
  const dropdowns = [];

  document
    .querySelectorAll("[data-dropdown-toggle]")
    .forEach(function ($triggerEl) {
      let dropdown;
      var dropdownId = $triggerEl.getAttribute("data-dropdown-toggle");
      var $dropdownEl = document.getElementById(dropdownId);
      if ($dropdownEl) {
        var placement = $triggerEl.getAttribute("data-dropdown-placement");
        var offsetSkidding = $triggerEl.getAttribute(
          "data-dropdown-offset-skidding"
        );
        var offsetDistance = $triggerEl.getAttribute(
          "data-dropdown-offset-distance"
        );
        var triggerType = $triggerEl.getAttribute("data-dropdown-trigger");
        var delay = $triggerEl.getAttribute("data-dropdown-delay");
        var ignoreClickOutsideClass = $triggerEl.getAttribute(
          "data-dropdown-ignore-click-outside-class"
        );

        dropdown = new Dropdown($dropdownEl, $triggerEl, {
          placement: placement ? placement : "bottom",
          triggerType: triggerType ? triggerType : "click",
          offsetSkidding: offsetSkidding ? parseInt(offsetSkidding) : 0,
          offsetDistance: offsetDistance ? parseInt(offsetDistance) : 10,
          delay: delay ? parseInt(delay) : 300,
          ignoreClickOutsideClass: ignoreClickOutsideClass
            ? ignoreClickOutsideClass
            : false,
        });

        dropdowns.push(dropdown);
      } else {
        console.error(
          'The dropdown element with id "'.concat(
            dropdownId,
            '" does not exist. Please check the data-dropdown-toggle attribute.'
          )
        );
      }

      if (!dropdown) return;

      const input = dropdown._targetEl.querySelector("input");

      if (input) {
        dropdown.updateOnShow(() => {
          setTimeout(() => {
            input.focus();
          }, 100);
        });

        // Handle search
        input.addEventListener("input", (e) => {
          const searchTerm = e.target.value.toLowerCase();
          const items = dropdown._targetEl.querySelectorAll("li");

          items.forEach((item) => {
            const text = item.textContent.trim().toLowerCase();
            if (text.includes(searchTerm)) {
              item.classList.remove("hidden");
            } else {
              item.classList.add("hidden");
            }
          });
        });

        dropdown._targetEl.addEventListener("click", (e) => {
          e.preventDefault();

          const link = e.target.closest("a");

          if (!link) return;

          const text = link.textContent.trim();

          dropdown._triggerEl.textContent = text;

          // Reset search
          input.value = "";
          const items = dropdown._targetEl.querySelectorAll("li");
          items.forEach((item) => {
            item.classList.remove("hidden");
          });
        });
      }
    });

  return dropdowns;
}

document.addEventListener("DOMContentLoaded", () => {
  const container = document.querySelector(".toggle-container");
  const buttons = document.querySelectorAll(".toggle-btn");
  const highlight = document.querySelector(".highlight");

  function updateToggle(activeBtn) {
    // Remove active state from all buttons
    buttons.forEach((btn) => {
      btn.classList.remove("active");
      btn.classList.remove("text-white");
      btn.classList.add("text-gray-700");
    });

    // Add active state to clicked button
    activeBtn.classList.add("active");
    activeBtn.classList.add("text-white");
    activeBtn.classList.remove("text-gray-700");

    // Move highlight
    const containerRect = container.getBoundingClientRect();
    const btnRect = activeBtn.getBoundingClientRect();
    const offsetLeft = btnRect.left - containerRect.left - 4; // 4px for padding
    highlight.style.transform = `translateX(${offsetLeft}px)`;
    highlight.style.width = `${btnRect.width}px`;
  }

  // Set initial state
  const initialActiveBtn = document.querySelector(".toggle-btn.active");
  if (initialActiveBtn) {
    updateToggle(initialActiveBtn);
  }

  // Add click handlers
  buttons.forEach((btn) => {
    btn.addEventListener("click", () => {
      updateToggle(btn);
    });
  });

  // Set language cookie on page load
  const currentLang = document.documentElement.lang || "en";
  const cookieName =
    window.location.hostname === "aragro.com"
      ? "_beta_locale"
      : "_staging_locale";
  setCookie(cookieName, currentLang);

  const dropdowns = initDropdowns();

  dropdowns.forEach((dropdown) => {
    dropdown._targetEl.addEventListener("click", function (e) {
      // Check if the clicked element is a dropdown item
      if (e.target.tagName === "A") {
        e.preventDefault();

        if (dropdown._triggerEl.matches(".select-btn")) {
          dropdown._triggerEl.textContent = e.target.textContent;
        }

        const input = dropdown._targetEl.querySelector("input[hidden]");

        if (input) {
          input.value = e.target.textContent;
        } else {
          console.warn("Input not found");
        }

        dropdown.hide();
      }
    });
  });
});
